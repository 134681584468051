import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { IForm } from "./form.models";

export interface IFormState extends EntityState<IForm> {
    isLoading: boolean;
    submitting: boolean;
    submittedSuccessfully: boolean;
    selectedFormId: string | null;
    error: string | null;
    errorList: { [key: string]: string }
}

export const formAdapter = createEntityAdapter<IForm>();

export const initialState: IFormState = formAdapter.getInitialState({
    isLoading: false,
    submitting: false,
    submittedSuccessfully: false,
    selectedFormId: null,
    error: null,
    errorList: {}
});