import { createAction, props } from "@ngrx/store";
import { IDuplicateFormRequest, IForm, IFormDisplayConfig, IFormResponse, IFormsResponse, INewFormRequest, INewFormResponse } from "./form.models";
import { IAPIErrorResponse, IAPIResponseHeaders } from "../../../../models/base";

export const loadForms = createAction('[Forms] Load Forms');
export const loadFormsSuccess = createAction('[Forms] Load Forms Success', props<IFormsResponse>());
export const loadFormsFailed = createAction('[Forms] Load Forms Failed', props<IAPIErrorResponse>());

export const loadForm = createAction('[Forms] Load Form', props<{ formId: string }>());
export const loadFormSuccess = createAction('[Forms] Form Load Success', props<IFormResponse>());
export const loadFormFailed = createAction('[Forms] Form Load Success', props<IAPIErrorResponse>());


export const addForm = createAction('[Forms] Add Form', props<INewFormRequest>());
export const addFormSuccess = createAction('[Forms] Add Form Success', props<INewFormResponse>());
export const addFormFailed = createAction('[Forms] Add Form Failed', props<IAPIErrorResponse>());

export const duplicateForm = createAction('[Forms] Duplicate Form', props<IDuplicateFormRequest>());
export const duplicateFormSuccess = createAction('[Forms] Duplicate Form Success', props<INewFormResponse>());
export const duplicateFormFailed = createAction('[Forms] Duplicate Form Failed', props<IAPIErrorResponse>());

export const updateFormConfig = createAction('[Forms] Update Form Config', props<IFormDisplayConfig>());
export const updateFormConfigSuccess = createAction('[Forms] Update Form Config Success', props<IAPIResponseHeaders>());
export const updateFormConfigFailed = createAction('[Forms] Update Form Config Failed', props<IAPIErrorResponse>());

export const updateForm = createAction('[Forms] Update Form', props<IForm>());
export const updateFormSuccess = createAction('[Forms] Update Form Success', props<IFormResponse>());
export const updateFormFailed = createAction('[Forms] Update Form Failed', props<IAPIErrorResponse>());

export const deleteForm = createAction('[Forms] Delete Form', props<{ id: string }>());
export const deleteFormSuccess = createAction('[Forms] Delete Form Success', props<{ id: string }>());
export const deleteFormFailed = createAction('[Forms] Delete Form Failed', props<IAPIErrorResponse>());

export const selectForm = createAction('[Forms] Select Form', props<{ id: string }>());

